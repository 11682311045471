import React from 'react';
import {
  Box,
  Flex,
  Image,
  Heading,
  Text,
  VStack,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  FaGithub,
  FaLinkedin,
  FaEnvelope,
  FaTwitter,
  FaPlus,
  FaMicroblog,
} from 'react-icons/fa';
import { FiFileText } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

const ProfileCard = ({
  profilePhotoSrc,
  profilePhotoAlt,
  profilePhotoBoxSize,
  profilePhotoObjectFit,
  profilePhotoRounded,
  profilePhotoMarginTop,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [isWiggling, setWiggling] = useState(true);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setWiggling(false);
    }, 1000);

    return () => clearTimeout(animationTimeout);
  }, []);

  const iconStyles = isWiggling
    ? { animation: 'wiggle 0.2s linear infinite' }
    : {};

  return (
    <>
      <style>
        {`
      @keyframes wiggle {
        0% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(-10deg);
        }
        75% {
          transform: rotate(10deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }
      `}
      </style>
      <Flex direction="column" alignItems="center">
        {isMobile && (
          <Flex alignItems="center" direction="column">
            <Image
              src={`${process.env.PUBLIC_URL}/${profilePhotoSrc}`}
              alt={profilePhotoAlt}
              boxSize={profilePhotoBoxSize}
              objectFit={profilePhotoObjectFit}
              rounded={profilePhotoRounded}
              mt={profilePhotoMarginTop}
              p="10"
            />
            <VStack spacing={2} alignItems="center" mt={4}>
              <Heading fontSize="4xl" textAlign="center">
                Giovanni Fernando
              </Heading>
              <Text fontSize="4xl" textAlign="center">
                Junior Software Engineer
              </Text>
            </VStack>
            <Flex mt={2} mb={10}>
              <Tooltip label="Github" aria-label="Github" hasArrow>
                <Box mr={30}>
                  <a
                    href="https://github.com/GioDoesntKnowCode"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaGithub size={24} />
                  </a>
                </Box>
              </Tooltip>
              <Tooltip
                label="LinkedIn"
                aria-label="LinkedIn"
                hasArrow
              >
                <Box mr={30}>
                  <a
                    href="https://www.linkedin.com/in/giovanni-fernando-084442216"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin size={24} />
                  </a>
                </Box>
              </Tooltip>
              <Tooltip label="Email" aria-label="Email" hasArrow>
                <Box mr={30}>
                  <a href="mailto:design-judo0d@icloud.com">
                    <FaEnvelope size={24} />
                  </a>
                </Box>
              </Tooltip>
              <Tooltip label="Twitter" aria-label="Twitter" hasArrow>
                <Box mr={30}>
                  <a
                    href="https://twitter.com/@giovanni_623"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter size={24} />
                  </a>
                </Box>
              </Tooltip>
              <Tooltip
                label="PDF Resume"
                aria-label="PDF Resume"
                hasArrow
              >
                <Box mr={30}>
                  <a
                    href="https://drive.google.com/drive/folders/1EdVJ-R0RdggskGs6RkSMTvhMN7YE0keP?usp=share_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FiFileText size={24} />
                  </a>
                </Box>
              </Tooltip>
              <Tooltip
                label="More about me:)"
                aria-label="More about me:)"
                hasArrow
              >
                <Box display="inline-block" mr={30}>
                  <Link as={ReactRouterLink} to="/Blog">
                    <FaMicroblog size={24} style={iconStyles} />
                  </Link>
                </Box>
              </Tooltip>
              <Tooltip
                label="More about me:)"
                aria-label="More about me:)"
                hasArrow
              >
                <Box display="inline-block">
                  <Link as={ReactRouterLink} to="/About">
                    <FaPlus size={24} style={iconStyles} />
                  </Link>
                </Box>
              </Tooltip>
            </Flex>
          </Flex>
        )}

        {!isMobile && (
          <Flex alignItems="center">
            <Image
              src={`${process.env.PUBLIC_URL}/${profilePhotoSrc}`}
              alt={profilePhotoAlt}
              boxSize={profilePhotoBoxSize}
              objectFit={profilePhotoObjectFit}
              rounded={profilePhotoRounded}
              mt={profilePhotoMarginTop}
              p="10"
            />
            <VStack spacing={2} alignItems="start" ml={4}>
              <Heading fontSize="4xl" textAlign="left">
                Giovanni Fernando
              </Heading>
              <Text fontSize="4xl" textAlign="left">
                Junior Software Engineer
              </Text>
              <Flex mt={2}>
                <Tooltip label="Github" aria-label="Github" hasArrow>
                  <Box mr={30}>
                    <a
                      href="https://github.com/GioDoesntKnowCode"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaGithub size={24} />
                    </a>
                  </Box>
                </Tooltip>
                <Tooltip
                  label="LinkedIn"
                  aria-label="LinkedIn"
                  hasArrow
                >
                  <Box mr={30}>
                    <a
                      href="https://www.linkedin.com/in/giovanni-fernando-084442216"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin size={24} />
                    </a>
                  </Box>
                </Tooltip>
                <Tooltip label="Email" aria-label="Email" hasArrow>
                  <Box mr={30}>
                    <a href="mailto:design-judo0d@icloud.com">
                      <FaEnvelope size={24} />
                    </a>
                  </Box>
                </Tooltip>
                <Tooltip
                  label="Twitter"
                  aria-label="Twitter"
                  hasArrow
                >
                  <Box mr={30}>
                    <a
                      href="https://twitter.com/@giovanni_623"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTwitter size={24} />
                    </a>
                  </Box>
                </Tooltip>
                <Tooltip
                  label="PDF Resume"
                  aria-label="PDF Resume"
                  hasArrow
                >
                  <Box mr={30}>
                    <a
                      href="https://drive.google.com/drive/folders/1EdVJ-R0RdggskGs6RkSMTvhMN7YE0keP?usp=share_link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FiFileText size={24} />
                    </a>
                  </Box>
                </Tooltip>
                <Tooltip
                  label="My Blog"
                  aria-label="My Blog"
                  hasArrow
                >
                  <Box display="inline-block" mr={30}>
                    <Link as={ReactRouterLink} to="/Blog">
                      <FaMicroblog size={24} style={iconStyles} />
                    </Link>
                  </Box>
                </Tooltip>
                <Tooltip
                  label="More about me:)"
                  aria-label="More about me:)"
                  hasArrow
                >
                  <Box display="inline-block">
                    <Link as={ReactRouterLink} to="/About">
                      <FaPlus size={24} style={iconStyles} />
                    </Link>
                  </Box>
                </Tooltip>
              </Flex>
            </VStack>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default ProfileCard;
