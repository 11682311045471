import { Box, Heading, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';

const Paper = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div align="center">
      <Box
        display="flex"
        justifyContent="flex-start"
        paddingTop="1rem"
        paddingLeft="1rem"
      >
        <Button
          leftIcon={<ArrowBackIcon />}
          colorScheme="teal"
          variant="outline"
          borderColor="white"
          color="white"
          onClick={handleBackClick}
          _hover={{
            bg: 'white',
            color: 'black',
          }}
        >
          To Home
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        marginBottom="4rem"
        paddingTop="4rem"
      >
        <Heading color="white">Graduate Thesis</Heading>
      </Box>

      <Box
        width="50%"
        bg="white"
        rounded="md"
        p="8"
        transition="opacity 0.5s, transform 0.5s"
        align="center"
      >
        <Box>
          <Box color="black">
            <Heading
              fontFamily="sans-serif"
              fontSize="xl"
              color="black"
            >
              Measuring the Energy Consumption of Docker-based
              Microservices via Hardware and Software Profilers.
            </Heading>
            <br />
            Authored this paper as part of my Bachelors Thesis at VU
            Amsterdam, I really enjoyed conducting this research and
            hope you enjoy reading it!
          </Box>
          <br />
          <VStack align="center" spacing={4} md={20}>
            <Box>
              <a href="https://drive.google.com/file/d/1DOMxD5CPQ-QeC8-s_eePLnFkNVONp-hI/view?usp=share_link">
                <Button colorScheme="blue">Open</Button>
              </a>
            </Box>
          </VStack>
        </Box>
      </Box>
    </div>
  );
};

export default Paper;
