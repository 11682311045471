import { Box, Grid, GridItem, Image, Text, Heading, Flex, useBreakpointValue, Button } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';

const Feed = () => {
  const posts = [
    {
      id: 1,
      imageSrc: "robae.png",
      title: "Where it all started",
      title2: "Meet RoBae",
      emoji: "\ud83e\udd16",
      desc: "The robot that made a 13 year old me decide that maybe programming isn't such a bad idea after all, ironically, this was completely analogue, but its a start!",
      link: "https://drive.google.com/drive/folders/11gcs0N8a6MnsTgTPHUOAfH2omZo8eZse?usp=share_link"
    },
    {
      id: 2,
      imageSrc: "photo.png",
      title: "Photography",
      title2: "Where all my money went",
      emoji: "\ud83d\udcf8",
      desc: "Always loved photography, and I would take any excuse to make content and invest in cameras. Now instead of spamming my instagram, I post my favourites to VSCO.",
      link: "https://vsco.co/giovannifernando/gallery"
    },
    {
      id: 3,
      imageSrc: "video.png",
      title: "Videography",
      title2: "Where all my time went",
      emoji: "\ud83d\udcf9",
      desc: "As soon as I got ahold of Imovie, as a 10 year old, I started making videos, first with gaming and then on to travel videos.",
      link: "https://www.youtube.com/channel/UCSZnYAnxruzpGbBYI6LiZ5A"
    },
    {
      id: 4,
      imageSrc: "rowing.png",
      title: "Pastime",
      title2: "Rowing",
      emoji: "\ud83d\udea3",
      desc: "Though I started late in life, I always loved sports and grew very fond of rowing, after learning at CRC colombo, I moved on to doing it a bit more seriously at Skøll Amsterdam, joining and then later coaching the team Heren Club Dubbel 4.",
      link: "https://instagram.com/herenclubdubbel4skoll?igshid=MmJiY2I4NDBkZg=="
    },
    {
      id: 5,
      imageSrc: "debate.png",
      title: "Public Speaking",
      title2: "MUN | Debate",
      emoji: "\ud83d\udcac",
      desc: "For the last 4 years of my school life, I loved debate and MUN, and would take any chance to get up on a stage and argue a point",
    },
    {
      id: 6,
      imageSrc: "sports.png",
      title: "Sports",
      title2: "Track & Field",
      emoji: "\ud83c\udfc6",
      desc: "Nothing felt cooler than throwing javelins into the distance and being good at it.",
    },
    {
      id: 7,
      imageSrc: "science.png",
      title: "In the name of Science",
      title2: "I must light this on fire",
      emoji: "\ud83d\udd25",
      desc: "I was wrong about nothing feeling cooler, this. this is cooler. \n\n This was for visualising sound waves, but music looks pretty good too!",
      link: "https://youtu.be/27hD9KdBnmM"
    },



  ];

  const cols = useBreakpointValue({ base: 'repeat(1, minmax(0, 1fr))', lg: 'repeat(3, minmax(0, 1fr))' });

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/Main");
  };

  return (
    <div >
      <Box display="flex" justifyContent="flex-start" paddingTop="1rem" paddingLeft="1rem">
        <Button leftIcon={<ArrowBackIcon />} colorScheme="teal" variant="outline" borderColor="white" color="white" onClick={handleBackClick}  _hover={{
    bg: 'white',
    color: 'black',
  }}>
          Back to Home
        </Button>
      </Box>
    

      <Box display="flex" justifyContent="center" marginBottom="4rem" paddingTop="4rem">
        <Heading color="white">About me</Heading>
      </Box>
      <Box display="flex" justifyContent="center">
        <Grid templateColumns={cols} gap={4} maxWidth="800px" marginBottom="10rem" mr={4} ml={4}>
          {posts.map((post) => (
            <GridItem key={post.id}>
              {post.link ? (
                <a href={post.link}>
                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    width="100%"
                    bg="white"
                    color="black"
                    transition="transform 0.2s"
                    _hover={{ transform: "scale(1.05)", boxShadow: "0 0 8px rgba(0, 0, 0, 0.3)" }}
                  >
                    <Image src={`${process.env.PUBLIC_URL}/${post.imageSrc}`} alt="Post Image" />
                    <Box p="4">
                      <Text fontSize="lg" fontWeight="bold">
                        {post.title}
                      </Text>
                      <Flex align="center">
                        {post.emoji && (
                          <Box display="inline-block" marginRight="0.5rem">
                            {post.emoji}
                          </Box>
                        )}
                        <Text fontSize="sm" fontWeight="bold">
                          {post.title2}
                        </Text>
                      </Flex>
                      <Text mt="2">{post.desc}</Text>
                    </Box>
                  </Box>
                </a>
              ) : (
                <Box
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  width="100%"
                  bg="white"
                  color="black"
                >
                  <Image src={`${process.env.PUBLIC_URL}/${post.imageSrc}`} alt="Post Image" />
                  <Box p="4">
                    <Text fontSize="lg" fontWeight="bold">
                      {post.title}
                    </Text>
                    <Flex align="center">
                      {post.emoji && (
                        <Box display="inline-block" marginRight="0.5rem">
                          {post.emoji}
                        </Box>
                      )}
                      <Text fontSize="sm" fontWeight="bold">
                        {post.title2}
                      </Text>
                    </Flex>
                    <Text mt="2">{post.desc}</Text>
                  </Box>
                </Box>
              )}
            </GridItem>


          ))}
        </Grid>
      </Box>
    </div>

  );
};

export default Feed;
