const firebaseConfig = {
  apiKey: 'AIzaSyBMY4cHpRE_28r-ZzCXBU-qN3Jhk7MFWhA',
  authDomain: 'home-api-dd1bd.firebaseapp.com',
  projectId: 'home-api-dd1bd',
  storageBucket: 'home-api-dd1bd.appspot.com',
  messagingSenderId: '104980009340',
  appId: '1:104980009340:web:dba0e4d3a4d93e02da54f9',
  measurementId: 'G-BYPE74448Y',
};

export default firebaseConfig;
