import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  VStack,
  Heading,
  Text,
  Divider,
} from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';
import ProfileCard from '../components/ProfileCard';
import { IconContext } from 'react-icons';
import * as Icons from 'react-icons/fa'; // Import all icons from react-icons/fa
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
} from '@chakra-ui/react';

const MainPage = () => {
  const [jsonData, setJsonData] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + '/jsonData.json')
      .then((response) => response.json())
      .then((data) => setJsonData(data))
      .catch((error) => console.error('Error fetching JSON:', error));
  }, []);

  if (!jsonData) {
    return null;
  }

  const getIconComponent = (iconName) => {
    const IconComponent = Icons[iconName]; // Get the icon component based on the iconName
    return IconComponent ? (
      <IconComponent
        size={24}
        style={{ color: '4c4c4c', marginRight: '0.5rem' }}
      />
    ) : null;
  };

  const {
    minHeight,
    padding,
    vStackSpacing,
    vStackWidth,
    vStackMaxWidth,
    profilePhotoSrc,
    profilePhotoAlt,
    profilePhotoBoxSize,
    profilePhotoObjectFit,
    profilePhotoRounded,
    profilePhotoMarginTop,
    cardData,
  } = jsonData;
  return (
    <Box minHeight={minHeight} padding={padding} href="#Main">
      <Flex direction="column" alignItems="center">
        <ProfileCard
          profilePhotoSrc={profilePhotoSrc}
          profilePhotoAlt={profilePhotoAlt}
          profilePhotoBoxSize={profilePhotoBoxSize}
          profilePhotoObjectFit={profilePhotoObjectFit}
          profilePhotoRounded={profilePhotoRounded}
          profilePhotoMarginTop={profilePhotoMarginTop}
          style={{
            opacity: isVisible ? 1 : 0,
            transform: `scale(${isVisible ? 1 : 0.3})`,
          }}
          transition="opacity 0.5s, transform 0.5s"
        />

        <VStack
          spacing={vStackSpacing}
          w={vStackWidth}
          maxW={vStackMaxWidth}
        >
          {cardData.map((entry, index) => (
            <Box
              key={index}
              width="100%"
              bg="white"
              rounded="md"
              p="8"
              ref={index === 0 ? ref : null}
              style={{
                opacity: isVisible ? 1 : 0,
                transform: `scale(${isVisible ? 1 : 0.3})`,
              }}
              transition="opacity 0.5s, transform 0.5s"
            >
              {entry.heading && (
                <Flex align="center">
                  <IconContext.Provider
                    value={{ className: 'react-icons' }}
                    style={{ color: 'black', marginRight: '0.5rem' }}
                  >
                    {getIconComponent(entry.icon)}
                  </IconContext.Provider>
                  <Heading
                    fontFamily="sans-serif"
                    fontSize="xl"
                    color="black"
                  >
                    {entry.heading}
                  </Heading>
                </Flex>
              )}
              {entry.contributions && (
                <Accordion color="black">
                  {entry.contributions.map((title, idx) => (
                    <AccordionItem key={idx}>
                      <h2>
                        <AccordionButton>
                          <Flex align="center">
                            <IconContext.Provider
                              value={{ className: 'react-icons' }}
                              style={{
                                color: 'black',
                                marginRight: '0.5rem',
                              }}
                            >
                              {getIconComponent(title.icon)}
                            </IconContext.Provider>
                            {title.title}
                          </Flex>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4} color="black">
                        <Box>
                          <Box>{title.description1}</Box>
                          {title.link && (
                            <VStack
                              align="center"
                              spacing={4}
                              md={20}
                            >
                              <Box>
                                <a href={title.link}>
                                  <Button colorScheme="blue">
                                    Visit me!
                                  </Button>
                                </a>
                              </Box>
                            </VStack>
                          )}
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              )}
              {entry.titles && (
                <VStack align="start" spacing={2}>
                  {entry.titles.map((title, idx) => (
                    <Box key={idx} mt={4}>
                      <Flex align="center">
                        {title.emoji && (
                          <Box
                            display="inline-block"
                            marginRight="0.5rem"
                          >
                            {title.emoji}
                          </Box>
                        )}
                        <Heading
                          fontFamily="sans-serif"
                          fontSize="md"
                          color="black"
                          fontWeight="normal"
                        >
                          {title.title}
                        </Heading>
                      </Flex>

                      {/* <Spacer /> */}
                      {title.company && (
                        <Text color="grey">{title.company}</Text>
                      )}
                      {title.year && (
                        <Text color="grey" fontSize="0.8em">
                          {title.year}
                        </Text>
                      )}

                      {title.description1 && (
                        <ul>
                          <li
                            style={{
                              color: '#596371',
                              marginLeft: '2em',
                            }}
                          >
                            {title.description1}
                          </li>
                        </ul>
                      )}

                      <Divider
                        style={{
                          visibility: 'hidden',
                          height: '0.4em',
                        }}
                      />

                      {title.description2 && (
                        <ul>
                          <li
                            style={{
                              color: '#596371',
                              marginLeft: '2em',
                            }}
                          >
                            {title.description2}
                          </li>
                        </ul>
                      )}
                      <Divider
                        style={{
                          visibility: 'hidden',
                          height: '1.4em',
                        }}
                      />
                    </Box>
                  ))}
                </VStack>
              )}
            </Box>
          ))}
        </VStack>
      </Flex>
    </Box>
  );
};

export default MainPage;
