import { Button, Box } from '@chakra-ui/react';
import BlogCard from '../components/BlogCard';
import { useNavigate } from 'react-router-dom';

import { ArrowBackIcon } from '@chakra-ui/icons';
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  collection,
  getDocs,
} from 'firebase/firestore';
import firebaseConfig from '../firebaseConfig';
import { useState, useEffect } from 'react';

const Blog = () => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/Main');
  };

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(
        collection(db, 'personalSite-blog')
      );
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const sortedData = data.sort(
        (a, b) =>
          new Date(b.date).getTime() - new Date(a.date).getTime()
      );

      setBlogs(sortedData.reverse());
    };
    fetchData();
  }, [db]);

  return (
    <div>
      <Box
        display="flex"
        justifyContent="flex-start"
        paddingTop="1rem"
        paddingLeft="1rem"
      >
        <Button
          leftIcon={<ArrowBackIcon />}
          colorScheme="teal"
          variant="outline"
          borderColor="white"
          color="white"
          onClick={handleBackClick}
          _hover={{
            bg: 'white',
            color: 'black',
          }}
        >
          Back to Home
        </Button>
      </Box>
      {blogs.map((blog, index) => (
        <BlogCard key={blog.id} blogData={blog} />
      ))}
    </div>
  );
};

export default Blog;
