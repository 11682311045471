import React from 'react';
import {
  Flex,
  useBreakpointValue,
  Card,
  CardBody,
  Text,
  Heading,
  Button,
  Box,
  VStack,
} from '@chakra-ui/react';
import { IconContext } from 'react-icons';
import * as Icons from 'react-icons/fa'; // Import all icons from react-icons/fa

const getIconComponent = (iconName) => {
  const IconComponent = Icons[iconName]; // Get the icon component based on the iconName
  return IconComponent ? (
    <IconComponent
      size={24}
      style={{ color: '4c4c4c', marginRight: '0.5rem' }}
    />
  ) : null;
};

const BlogCard = ({ blogData }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      {blogData.Title ? (
        <Flex direction="column" alignItems="center" padding={10}>
          <Flex
            alignItems="center"
            direction={isMobile ? 'column' : 'row'}
          >
            <Card w={isMobile ? '200px' : '500px'}>
              <CardBody>
                <Heading size="md" paddingBottom={1}>
                  <VStack spacing={0} alignItems="flex-start">
                    <Flex alignItems="center">
                      <IconContext.Provider
                        value={{ className: 'react-icons' }}
                      >
                        <div>
                          {getIconComponent(blogData.Icon)
                            ? new Date(
                                blogData.Date.seconds * 1000
                              ).toLocaleDateString()
                            : getIconComponent('FaScroll')}
                        </div>
                      </IconContext.Provider>
                      <Text ml="0.5rem" paddingBottom={1}>
                        {blogData.Title}
                      </Text>
                    </Flex>
                    <Text fontSize="sm" fontWeight={'normal'}>
                      {new Date(
                        blogData.Date.seconds * 1000
                      ).toLocaleDateString()}{' '}
                      {blogData.Source
                        ? `via ${blogData.Source}`
                        : null}
                    </Text>
                  </VStack>
                </Heading>

                <Text> {blogData.SubTitle1} </Text>
                <Text> {blogData.SubTitle2} </Text>
                {blogData.linkText ? (
                  <VStack align="center" spacing={4} md={20}>
                    <Box>
                      <a href={blogData.link}>
                        <Button colorScheme="blue">
                          {blogData.linkText}
                        </Button>
                      </a>
                    </Box>
                  </VStack>
                ) : null}
              </CardBody>
            </Card>
          </Flex>
        </Flex>
      ) : null}
    </>
  );
};

export default BlogCard;
